import workplacesIcon from '../../images/nav-icon/workplaces.png'
import inventoryIcon from '../../images/nav-icon/inventory.png'
import upgradeIcon from '../../images/nav-icon/upgrade.png'
import researchIcon from '../../images/nav-icon/research.png'
import swapIcon from '../../images/nav-icon/swap.png'



export const navMenu = [
    {
        title: 'Workplaces',
        icon: workplacesIcon,
        url: '/workplaces'
    },
    {
        title: 'Inventory',
        icon: inventoryIcon,
        url: '/inventory'
    },
    {
        title: 'Upgrade',
        icon: upgradeIcon,
        url: '/upgrade'
    },
    {
        title: 'Swap',
        icon: swapIcon,
        url: '/swap'
    },
    {
        title: 'Research',
        icon: researchIcon,
        url: '/research'
    },
]